@font-face {
  font-family: gorthia-black;
  src: url("./font/Gordita\ Black.otf");
}
@font-face {
  font-family: gorthia-regular;
  src: url("./font/Gordita\ Regular.otf");
}
@font-face {
  font-family: gorthia-light;
  src: url("./font/Gordita\ Light.otf");
}
@font-face {
  font-family: gorthia-medium;
  src: url("./font/Gordita\ Medium.otf");
}
@font-face {
  font-family: gorthia-thin;
  src: url("./font/Gordita\ Thin.otf");
}
@font-face {
  font-family: gorthia-ultra;
  src: url("./font/Gordita\ Ultra.otf");
}
@font-face {
  font-family: gorthia-bold;
  src: url("./font/Gordita\ Bold.otf");
}

* {
  font-family: gorthia-regular;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}

.slide-item{
  /* height: 500px; */
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(10px);
  -webkit-mask-image: linear-gradient(to top, transparent 10%, #000 30%);
}

.slide-item h1,h5{
  color: #fff;
}

/* .slide-item .info{
  background: linear-gradient(to right, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%);

} */

.slide-item h5{
  font-size: 13px;
  margin-bottom: 15px;
}
.slide-item p{
  color: rgb(216, 216, 216);
  font-size: 13px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: #7A7A7A; 
    background-color: #1c1e21;
    border: none;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #1c1e21;
}

.nav-tabs{
  border: none;
}


.movie, .cast , .review, .tv{
  background-color: rgb(36, 37, 38);
  margin: .2rem
}

.review{
  color: #fff;
  padding: 20px;
}

.author{
  margin-left: 15px;
  font-size: 20px;
  margin-bottom: 0;
}

.review-date, .review-content{
  font-size: 13px;
  color: #7A7A7A;
}



.label{
  color: #fff;
}

.details {
  padding: 20px 10px;
}

.details h4 {
  color: rgb(228, 230, 235);
  font-size: 14px;
}

.poster img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.rate {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.rate ion-icon {
  color: orange;
  font-size: 12px;
  margin-right: 10px;
}

.rate p {
  font-size: 12px;
  color: rgb(176, 179, 184);
  margin: 0;
}

.date p,
.character p {
  color: rgba(235, 235, 245, 0.6);
  font-size: 10px;
}

.spinner{
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 3px solid rgb(74 74 74);
    border-left-color: #0192fd;
    animation:  spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hero,.img{
  background-position: center;
  background-size: cover;
  color: #fff;
}

.layer{
  
    padding: 50px;
    background: rgba(0,0,0,0.7);
    /* -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px); */
}

.slider{
  padding: 215px 50px;
     background: rgba(0,0,0,0.5);
    /* -webkit-backdrop-filter: saturate(180%) blur(1px);
    backdrop-filter: saturate(180%) blur(1px); */
}

.hero img{
  width: 100%;
  border-radius: 5px;
}

.descr h1{
  font-size: 35px;
}
.descr h3{
  font-size: 20px;
}
.descr p{
  color: #929292;
  font-size: 15px;
}
.cta{
  color: #fff;
  padding: 10px;
  /* border:  1px solid #fff; */
  display: inline-block;
  border-radius: 5px;
  font-size: 15px;
}

.cta ion-icon{
  font-size: 20px;
} 

.cta:hover{
  color: #fff;
}

.md{
  font-size: 12px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.modal-content{
  background-color: rgb(36, 37, 38);
}
.modal-body{
  color: #7A7A7A;
  font-size: 14px;
}

.btn-close{
  color: #7A7A7A !important;
}

.btn-close:focus{
  outline: none;
  border: none;
}

.modal-title{
  font-size: 16px;
}

.modal-header{
  border: none;
}

.search{
  width: 100%;
  border: none;
  background-color: rgb(54, 56, 58);
  font-size: 13px;
  color: #7A7A7A;
}
.search:focus, .search:active, .btn-close:focus, .btn-close:active {
outline: none;
border: none;
 box-shadow: none;
}

.navbar-dark, .navbar-toggler:active, .navbar-dark .navbar-toggler:focus{
  border: none;
  outline: none;
  box-shadow: none;
}
.navbar-dark, .navbar-toggler, .navbar-dark .navbar-toggler{
  border: none;
  outline: none;
  box-shadow: none;
}
.search-wrapper{
  background-color: rgb(54, 56, 58);
  border-radius: 5px;
  padding: 5px 10px;
}
.bg-dark{
 background-color: #27292c !important;
}

.my-btn{
  border: none;
  background-color: rgb(54, 56, 58);
}

.my-icon{
  font-size: 20px;
  color: red
}

.nav-link{
  font-size: 14px;
}

.vid{
  color: #fff;
  padding: 10px;
}

.vid p{
  font-size: 13px;
  margin: 0;

}
.vid ion-icon{
  font-size: 55px;
  margin-right: 10px;
}

.controls ion-icon{
  bottom: 0;
  color: #fff;
  font-size: 25px;
}

.vid a{
  color: #fff;
}

/* .searchM{
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #27292c ;
  display: none;
}

.searchM{
  color: #fff;
} */

.heading{
  color: #fff;
  font-size: 20px;
}
.badge{
  font-size: 9px;
  margin-right: 5px;
}

.seasons .detail{
  padding-left: 30px;
}

.seasons .detail h1{
  font-size: 20px;
}
.seasons .detail p{
  color: #7A7A7A;
  font-size: 13px;
}

.seasons img{
  border-radius: 2px;
}

.footer{
  padding: 50px 0;
   background-color: rgb(36, 37, 38);
}

.footer p{
  color: #7A7A7A;
  font-size: 12px;
}

.no_img{
  position: relative;
}
.image_icon{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(54, 56, 58);
}

.image_icon ion-icon{
  font-size: 30px;
  color: #bdbdbd;
}